import React, { Component } from "react";
import PhotoContract from "./contracts/Photo.json";
import getWeb3 from "./getWeb3";

import "./App.css";

class App extends Component {
  state = { storageValue: 0, web3: null, accounts: null, contract: null, uriData: '' };

  componentDidMount = async () => {
    try {
      // Get network provider and web3 instance.
      const web3 = await getWeb3();

      // Use web3 to get the user's accounts.
      const accounts = await web3.eth.getAccounts();
      web3.eth.defaultAccount = web3.eth.accounts[0];

      // Get the contract instance.
      const networkId = await web3.eth.net.getId();
      console.log("Network id ",networkId);
      const deployedNetwork = PhotoContract.networks[networkId];
      const instance = new web3.eth.Contract(
        PhotoContract.abi,
        deployedNetwork && deployedNetwork.address,
      );

      console.log("instance.events test : ",instance.events);
      const selectedAccount = accounts[0];
  
      // Set web3, accounts, and contract to the state, and then proceed with an
      // example of interacting with the contract's methods.
      this.setState({ web3, selectedAccount, contract: instance, canCreatePhoto: true, isAuthor: false, editImage: "", inputData: "" }, this.initData);
    } catch (error) {
      // Catch any errors for any of the above operations.
      alert(
        `Failed to load web3, accounts, or contract. Install MetaMask extension.`,
      );
      console.error(error);
    }
  };

  initData = async () => {
    const { selectedAccount, contract } = this.state;

    const fee = await contract.methods.getFeeValue().call({ from: selectedAccount });
    const myToken = await contract.methods.getMyTokenId().call({ from: selectedAccount });
    // eslint-disable-next-line
    this.setState({ myTokenId: myToken, canCreatePhoto: myToken == 0, fee: fee});

    if (myToken > 0) {
      const photoUrl = await contract.methods.tokenURI(myToken).call({ from: selectedAccount });
      this.setState({ photoUrl: photoUrl});
      console.log("User with token : ", myToken);
    } else {
      console.log("User without token");
    }

    const currentAuthor = await contract.methods.getAuthor().call({ from: selectedAccount });
    this.setState({ author: currentAuthor, isAuthor: (currentAuthor === selectedAccount)});

  };

  mint = (photoUrl) => {
    if (photoUrl === undefined || photoUrl === "") {
      return;
    }
    let myapp = this;
    this.state.contract.methods.createPhoto(photoUrl)
      .send({ value: this.state.fee, from: this.state.selectedAccount }).then((result) => {
        myapp.setState({ photoUrl: photoUrl});
        myapp.state.contract.methods.getMyTokenId().call({ from: myapp.state.selectedAccount }).then((result) => {
          myapp.setState({ myTokenId: result, canCreatePhoto: result === 0});
        });
      });
      this.setState({ canCreatePhoto: false});
    }

  update = (photoUrl) => {
    if (photoUrl === undefined || photoUrl === "") {
      return;
    }

    this.state.contract.methods.setTokenURI(this.state.myTokenId,photoUrl).send({ value: this.state.fee, from: this.state.selectedAccount });
    this.setState({ photoUrl: photoUrl});
  }

  changeFee = (newFee) => {
    if (newFee === undefined || newFee === this.state.author) {
      return;
    }

    this.state.contract.methods.setFeeValue(""+(newFee*(10 ** 18))).send({ from: this.state.selectedAccount }).then((result) => {
      this.setState({ fee: newFee*(10 ** 18)});
    }).catch((err) => {
      alert(err.message);
    });
  }

  changeAuthor = (newAuthor) => {
    if (newAuthor === undefined || newAuthor === this.state.fee) {
      return;
    }

    this.state.contract.methods.setNewAuthor(newAuthor).send({ from: this.state.selectedAccount }).then((result) => {
      this.setState({ author: newAuthor, isAuthor: (newAuthor === this.state.selectedAccount)});
    }).catch((err) => {
      alert(err.message);
    });
  }
  

  previewData = (dataUrl) => {
    if (dataUrl === undefined) {
      return;
    }

    const postBody = {
      uri: dataUrl
    };
    const requestMetadata = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify(postBody)
    };

    fetch("/api/data",requestMetadata)
      .then(
        (result) => {
          this.setState({editImage: result.imageUrl});
          console.log(result);
        },
        (error) => {
          console.log(error);
        }
      );
  }

  burn = async () => {
    const { selectedAccount, contract, myTokenId } = this.state;

    const tokenExists = await contract.methods.exists(myTokenId).call({ from: selectedAccount });
    if(!tokenExists) {
      alert("Photo " + myTokenId + " don't exist");
      return;
    }

    this.state.contract.methods.burn(myTokenId).send({ from: this.state.selectedAccount }).then((result) => {
      this.setState({ myTokenId: 0, canCreatePhoto: true});
    }).catch((err) => {
      alert(err.message);
    });
  }

  render() {

    const midbutton = {
      margin: 'auto',
      'maxWidth': '150px',
    };

    const midTextInput = {
      margin: 'auto',
      'maxWidth': '450px',
    };


    if (!this.state.web3) {
      return <div>
        <p className="text-center mt-3">
        Loading Web3, accounts, and contract... <br/>
        <a href="https://metamask.io/download/" target="_blank" rel="noopener noreferrer">Install MetaMask extension !</a>
        </p>
        </div>;
    }
    return (
      <div>
          <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark p-0">
            <div className="container-fluid">
              <div className="navbar-brand">Profile Photo</div>
              <ul className="navbar-nav px-3">
                <li className="nav-item text-nowrap d-none d-sm-none d-sm-block">
                  <small className="text-white"><span id="account">{this.state.selectedAccount}</span></small>
                </li>
              </ul>
            </div>
          </nav>

          <section className="highlight-clean mt-5">
            <div className="container">
                <div className="intro">
                    <h1 className="text-center">NFT Profile Photo</h1>
                    <p className="text-center">Anybody can create one and only one NFT Profile Photo</p>
                    {this.state.fee > 0 &&
                      <p className="text-center">Transaction fee : {this.state.fee/(10 ** 18)} ETH</p>
                    }
                </div>
                {this.state.canCreatePhoto &&
                <div className="content mr-auto ml-auto">
                <form>
                  <input
                        type='text'
                        className='form-control mb-1'
                        placeholder='https://myphoto.com/photo.png'
                        value={this.state.uriData}
                        id='dest'
                        style={midTextInput}
                        onChange={(event) => {
                          this.setState({uriData: event.target.value});
                        }}
                  />
                  <input
                    type='submit'
                    className='btn btn-block btn-primary'
                    value='Create my photo'
                    style={midbutton}
                    onClick={(event) => {
                      console.log("EEE ",this.state.uriData);
                      event.preventDefault()
                      this.mint(this.state.uriData)
                      event.target.dest.value = "";
                      this.setState({editImage: ""});
                    }}
                  />
                  <input
                    type='submit'
                    className='btn btn-block btn-primary'
                    value='Preview'
                    style={midbutton}
                    onClick={(event) => {
                      console.log("AAA ",event);
                      event.preventDefault()
                      this.previewData(this.state.uriData)
                    }}
                  />
                </form>
                </div>
                }
                {!this.state.canCreatePhoto && this.state.photoUrl !== undefined &&
                  <div>
                  <div className="row mt-2">
                  <div className="col-sm-6 col-md-3 col-lg-3" id="profilephoto">

                  <svg viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <pattern id="img" patternUnits="userSpaceOnUse" width="100" height="100">
                        <image xlinkHref={this.state.photoUrl} x="-25" width="150" height="100" />
                      </pattern>
                    </defs>
                    <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#img)"/>
                  </svg>
                  </div>
                  </div>
                  <div className="mr-auto mt-2">
                  <form onSubmit={(event) => {
                    event.preventDefault()
                    this.update(event.target.newValue.value)
                    this.setState({editImage: ""});
                  }}>
                    <input
                          type='text'
                          className='form-control mb-1'
                          id='newValue'
                          defaultValue={this.state.photoUrl}
                          style={midTextInput}
                        />
                    <input
                      type='submit'
                      className='btn btn-block btn-primary'
                      value='Update my photo'
                      style={midbutton}
                    />

                  </form>
                  </div>
                  </div>
                  }

                    {this.state.editImage !== "" &&
                     <div className="col-sm-6 col-md-3 col-lg-3" id="previewphoto">
                       <p className="text-center mt-2">Preview</p>
                    <svg key={this.state.editImage} viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg" >
                    <defs >
                      <pattern id="img2" patternUnits="userSpaceOnUse" width="100" height="100" >
                        <image xlinkHref={this.state.editImage}  x="-25" width="150" height="100" />
                      </pattern>
                    </defs>
                    <polygon points="50 1 95 25 95 75 50 99 5 75 5 25" fill="url(#img2)"/>
                  </svg>

                    </div>
                    }


                  {!this.state.canCreatePhoto && this.state.photoUrl !== undefined &&
                  <div className="mr-auto mt-2">
                  <form onSubmit={(event) => {
                    event.preventDefault()
                    this.burn()
                    this.setState({editImage: ""});
                  }}>
                    <input
                      type='submit'
                      className='btn btn-block btn-danger'
                      value='Destroy NFT'
                      style={midbutton}
                    />
                  </form>
                  </div>
                }

                {this.state.isAuthor &&
                <div className="content mr-auto ml-auto mt-2">
                <form onSubmit={(event) => {
                  event.preventDefault()
                  this.changeFee(event.target.newFee.value)
                }}>
                  <input
                        type='text'
                        className='form-control mb-1'
                        placeholder=''
                        defaultValue={this.state.fee/(10 ** 18)}
                        id='newFee'
                        style={midTextInput}
                      />
                  <input
                    type='submit'
                    className='btn btn-block btn-primary'
                    value='Change fee'
                    style={midbutton}
                  />
                </form>
                </div>
                }
                {this.state.isAuthor &&
                <div className="content mr-auto ml-auto mt-2">
                <form onSubmit={(event) => {
                  event.preventDefault()
                  this.changeAuthor(event.target.newFee.value)
                }}>
                  <input
                        type='text'
                        className='form-control mb-1'
                        placeholder=''
                        defaultValue={this.state.author}
                        id='newFee'
                        style={midTextInput}
                      />
                  <input
                    type='submit'
                    className='btn btn-block btn-primary'
                    value='Change author'
                    style={midbutton}
                  />
                </form>
                </div>
                }


                <div className="mb-3">

                </div>

            </div>
          </section>

          <section>
            <div className="container mt-5">
            
            </div>
          </section>

          <section className="container mt-5">
            <div className="row">
            </div>
          </section>

          <footer className="page-footer font-small blue fixed-bottom">

          <div className="footer-copyright text-center py-3">© 2022 Copyright:
            <a href="https://liard.me/"> Samuel Liard</a>
          </div>

          </footer>
      </div>
    );
  }



}

export default App;


